import React from "react";

class Chat extends React.Component {
  render() {
    return (
        <a
        href="https://wa.me/+19127925827"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fab fa-whatsapp whatsapp-icon"></i>
      </a>
      // <></>
    );
  }
}
export default Chat;
